import { Button, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import SalesMarketing from "./salesMarketing/salesMarketing";
import Transactions from "./transactions/transactions";
import GrowthForecast from "./growthForecast/growthForecast";
import AnalyticDashboard from "./analyticDashboard/analyticDashboard";
import { useAppDispatch, useAppSelector } from "../../../hooks/hooks";
import {
  getBranchTransaction,
  getCustomerSurvey,
  getDistributionTransactionRevenue,
  getEmailReports,
  getForecastDetail,
  getForecastRevenuePlan,
  getGainLostAccounts,
  getGrowthRate,
  getGrowthRateBenchmark,
  getGrowthScore,
  getIndustryByRevenueDetail,
  getMarketByCustomer,
  getNetPromoter,
  getNpsScore,
  getProductByCustomerCount,
  getProductByRevenue,
  getProspectLeadsReports,
  getPurchaseDistribution,
  getRankByEmail,
  getRankByStableAccount,
  getRetentionLeads,
  getLastPurchaseCustomerLeads,
  getRetentionRate,
  getAtriskDetail,
  getCustomerSurveyDetail,
  getLastPurchaseDetail,
  getMarketOverview,
  getProspectLeadsReportsDetail,
  getProductAnalysisDetail,
  getGrowthRateDetail,
} from "../../../store/thunks/portalManagementThunk";
import DashboardHeader from "../../reusableComponents/dashboardHeader/dashboardHeader";

const Analytics = () => {
  const { selectedClient, selectedLastUpdated } = useAppSelector(
    (state: any) => state.portalManagement
  );
  const dispatch = useAppDispatch();
  const [showAnalyticDashboard, setShowAnalyticDashboard] = useState(true);
  const [showGrowthForecast, setShowGrowthForecast] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showSalesMarketing, setShowSalesMarketing] = useState(false);
  const handleNavigation = (section: string) => {
    if (section === "dashboard") {
      setShowAnalyticDashboard(true);
      setShowGrowthForecast(false);
      setShowTransactions(false);
      setShowSalesMarketing(false);
    } else if (section === "growthForecast") {
      setShowAnalyticDashboard(false);
      setShowGrowthForecast(true);
      setShowTransactions(false);
      setShowSalesMarketing(false);
    } else if (section === "transactions") {
      setShowAnalyticDashboard(false);
      setShowGrowthForecast(false);
      setShowTransactions(true);
      setShowSalesMarketing(false);
    } else if (section === "salesmarketing") {
      setShowAnalyticDashboard(false);
      setShowGrowthForecast(false);
      setShowTransactions(false);
      setShowSalesMarketing(true);
    }
  };

  useEffect(() => {
    if (selectedClient) {
      dispatch(getGrowthScore({ customer_id: selectedClient.customer_id }));
      dispatch(getAtriskDetail({ customer_id: selectedClient.customer_id }));
      dispatch(getNpsScore({ customer_id: selectedClient.customer_id }));
      dispatch(getRetentionLeads({ customer_id: selectedClient.customer_id }));
      dispatch(
        getLastPurchaseCustomerLeads({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getLastPurchaseDetail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(getEmailReports({ customer_id: selectedClient.customer_id }));
      dispatch(
        getGrowthRate({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getGrowthRateBenchmark({ customer_id: selectedClient.customer_id })
      );
      dispatch(getForecastDetail({ customer_id: selectedClient.customer_id }));
      dispatch(getNetPromoter({ customer_id: selectedClient.customer_id }));
      dispatch(getCustomerSurvey({ customer_id: selectedClient.customer_id }));
      dispatch(getRetentionRate({ customer_id: selectedClient.customer_id }));
      dispatch(
        getPurchaseDistribution({ customer_id: selectedClient.customer_id })
      );
      dispatch(
        getBranchTransaction({ customer_id: selectedClient.customer_id })
      );
      dispatch(
        getDistributionTransactionRevenue({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getIndustryByRevenueDetail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getForecastRevenuePlan({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getProspectLeadsReports({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getProspectLeadsReportsDetail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getGainLostAccounts({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getMarketByCustomer({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getMarketOverview({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getProductByCustomerCount({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getProductByRevenue({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getRankByStableAccount({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getRankByEmail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getCustomerSurveyDetail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getProductAnalysisDetail({
          customer_id: selectedClient.customer_id,
        })
      );
      dispatch(
        getGrowthRateDetail({
          customer_id: selectedClient.customer_id,
        })
      );
    }
  }, []);

  return (
    <Grid container sx={{}}>
      <Grid
        container
        sx={{
          backgroundColor: "#4C4C4C",
          borderTop: "5px solid white",
          height: "40px",
          paddingLeft: "10px",
        }}
      >
        <Button
          onClick={() => handleNavigation("dashboard")}
          sx={{
            color: showAnalyticDashboard ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Dashboard
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleNavigation("growthForecast")}
          sx={{
            color: showGrowthForecast ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Growth & Forecast
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleNavigation("transactions")}
          sx={{
            color: showTransactions ? "#38B54A" : "white",
            padding: "0px 20px 0px 20px",
          }}
        >
          Transactions
        </Button>
        <Divider
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ color: "white", backgroundColor: "white" }}
        />
        <Button
          onClick={() => handleNavigation("salesmarketing")}
          sx={{
            color: showSalesMarketing ? "#38B54A" : "white",
            padding: "2px 20px 2px 20px",
          }}
        >
          Sales & Marketing
        </Button>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          borderRight: "10px solid white",
          borderLeft: "10px solid white",
          backgroundColor: "rgba(176, 215, 255, 0.1)",
          height: "100vh",
          border: "1ps solid red",
        }}
      >
        <DashboardHeader
          title={selectedClient.customer_name}
          headerType={"analytic"}
          lastUpdated={selectedLastUpdated}
        />
        {showAnalyticDashboard && <AnalyticDashboard />}
        {showGrowthForecast && <GrowthForecast />}
        {showTransactions && <Transactions />}
        {showSalesMarketing && <SalesMarketing />}
      </Grid>
    </Grid>
  );
};

export default Analytics;
