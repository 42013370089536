import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import valid from "card-validator";

export const backendURL =
  process.env.NODE_ENV !== "production" ? "http://localhost:5000" : "";

export const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const GetArray = (object: any) => {
  if (object && Object?.keys(object).length > 0) {
    return Object?.keys(object)?.reduce((r: any, k: any) => {
      if (Array.isArray(object[k])) {
        object[k].forEach((a: any, i: any) => {
          r[i] = r[i] || {};
          r[i][k] = a;
        });
      }
      return r;
    }, []);
  } else return [];
};

export function GetObject(array: any[]) {
  return array?.reduce((r, o, i) => {
    if (Array.isArray(array)) {
      Object.keys(o).forEach((k) => {
        r[k] = r[k] || [];
        r[k][i] = o[k];
      });
    } else {
      r = array[o];
    }
    return r;
  }, {});
}

export function ConvertToObjectList(items: any[]) {
  return items.map((e) => {
    return {
      label: e,
      value: e,
    };
  });
}

export function WithCommas(x: any) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function IsEmpty(obj: {}) {
  return Object.keys(obj).length === 0;
}

export function TableIsEmpty(table: any) {
  return table.length === 0;
}

export const SaveFileBlob = (buffer: any, filename: any) => {
  import("file-saver").then(({ default: FileSaver }) => {
    const EXCEL_TYPE =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      filename + "_export_" + new Date().getTime() + EXCEL_EXTENSION
    );
  });
};

export const compareTwoObjectArray = (a: any, b: any, key: string) => {
  return (
    a.length === b.length &&
    a.every((element_1: any) =>
      b.some((element_2: any) =>
        Object.keys(element_1).every((key) => element_1[key] === element_2[key])
      )
    )
  );
};

export const compareTwoStringArray = (a: any, b: any) => {
  return (
    a.length === b.length &&
    a.every((element_1: any) =>
      b.some((element_2: any) => element_1 === element_2)
    )
  );
};

export const exportToExcel = (data: any, filename: any) => {
  const exportData: any[] = [];
  data.forEach((element: any) => {
    exportData.push(element.original);
  });

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${filename}.xlsx`);
};

export const exportToExcelOther = (data: any, filename: any) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, `${filename}.xlsx`);
};

export function validatCard(values: any) {
  let errors: any = {};
  let creditCard: any = valid.number(values.cardNumber);

  creditCard.expirationDate = valid.expirationDate(values.cardExpiration);
  creditCard.cvv = valid.cvv(values.cardCvc);
  creditCard.cardholderName = valid.cardholderName(values.cardName);
  creditCard.postalCode = valid.postalCode(values.zipCode);

  errors.message = "An unknown error occured. Please try again later";
  errors.cardName = false;
  errors.cardNumber = false;
  errors.cardIssuer = false;
  errors.cardExpiration = false;
  errors.cardCvc = false;
  errors.zipCode = false;
  errors.isValid = false;

  if (values.zipCode === null || !values.zipCode.trim()) {
    errors.message = "Credit card postal code is not complete";
  } else if (valid.postalCode(values.zipCode)) {
    errors.zipCode = true;
  } else {
    errors.message = "Credit card postal code is invalid";
  }

  // Card CVV expiration
  if (values.cardCvc === null || !values.cardCvc.trim()) {
    errors.message = "Credit card CVC is not complete";
  } else if (creditCard.cvv.isValid) {
    errors.cardCvc = true;
  } else {
    errors.message = "Credit card CVC is invalid";
  }

  // Card Expiration Verification
  if (values.cardExpiration === null || !values.cardExpiration.trim()) {
    errors.message = "Credit card expiration date is not complete";
  } else if (creditCard.expirationDate.isValid) {
    errors.cardExpiration = true;
  } else {
    errors.message = "Credit card expiration date is invalid";
  }

  // Card Type Verification
  if (
    values.cardIssuer === null ||
    !values.cardIssuer.trim() ||
    creditCard.card === null
  ) {
    errors.message = "Credit card type is not complete";
  } else if (
    creditCard.card.type &&
    creditCard.card.type.toUpperCase() === values.cardIssuer.toUpperCase()
  ) {
    errors.cardIssuer = true;
  } else {
    errors.message = "Credit card type is invalid";
  }

  // Card Number Verification
  if (values.cardNumber === null || !values.cardNumber.trim()) {
    errors.message = "Credit card number is not complete";
  } else if (creditCard.isValid) {
    errors.cardNumber = true;
  } else {
    errors.message = "Credit card number is invalid";
  }

  //Cardholder Name Verification
  if (values.cardName === null || !values.cardName.trim()) {
    errors.message = "Cardholder name is not complete";
  } else if (valid.cardholderName(values.cardName)) {
    errors.cardName = true;
  } else {
    errors.message = "Cardholder name is invalid";
  }

  if (errors.zipCode) {
    errors.variant = "success";
    errors.message = "Credit Card is valid";
    errors.isValid = true;
  }

  return errors;
}
